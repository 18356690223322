/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import { GTM_EVENT_KEY_ERROR_MESSAGE } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Forel/Gtm/Event/ErrorMessage/fireErrorMessageEvent */
export const fireErrorMessageEvent = debounceCallback(async (message) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_ERROR_MESSAGE,
        customerId,
        message
    });
});
